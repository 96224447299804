<template>
  <div>
    <Header/>
    <router-view :key="$route.path"></router-view>
    <Footer/>
  </div>
</template>

<script>
import Header from './inc/Header2'
import Footer from './inc/Footer2'

export default {
  name: "Layout",
  components: {
    Header,
    Footer
  }
}
</script>

<style>

</style>